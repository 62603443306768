import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import {
  Banner,
  Head,
  Layout,
  List,
  Section,
  Separator,
  Typography,
} from "../components"

export const ProjectPageTemplate = ({
  title,
  subheading,
  listTitle,
  list,
  section,
}) => (
  <>
    <Banner interior title={title} subheading={subheading} />
    <Section>
      <Typography variant="large">{listTitle}</Typography>
      <List items={list} />
    </Section>
    <Section
      variant="image-left"
      image={
        section.image.childImageSharp
          ? section.image.childImageSharp.fluid
          : null
      }
    >
      <Typography variant="large">{section.title}</Typography>
      <Separator />
      {section.content.map((content, i) => (
        <Typography key={`section-s-${i}`}>{content}</Typography>
      ))}
    </Section>
  </>
)

ProjectPageTemplate.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  listTitle: PropTypes.string,
  list: PropTypes.array,
  section: PropTypes.object,
}

const ProjectPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <Head title={frontmatter.title} description={frontmatter.subheading} />
      <ProjectPageTemplate
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        listTitle={frontmatter.list_title}
        list={frontmatter.list}
        section={frontmatter.section}
      />
    </Layout>
  )
}

ProjectPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ProjectPage

export const pageQuery = graphql`
  query ProjectPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "project-page" } }) {
      frontmatter {
        title
        subheading
        list_title
        list {
          title
          content
        }
        section {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          content
        }
      }
    }
  }
`
